import { useEffect } from 'react'
import { GetStaticPaths, GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import { firebaseAdmin } from '../util/firebaseAdmin'

type AdCampaign = {
  name: string
  page: string
  cost?: number
}

export default function RedirectPage({
  campaign,
}: {
  campaign: AdCampaign | undefined
}): JSX.Element {
  const router = useRouter()

  useEffect(() => {
    if (campaign) {
      router.push(
        `/?utm_medium=${
          (campaign.cost ?? 0) > 0 ? 'paid' : 'organic'
        }&utm_source=short_url&utm_campaign=${campaign.page}`,
      )
    } else {
      router.push('/')
    }
  }, [campaign])

  return <main className=""></main>
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const adRedirect = (params?.adRedirect ?? '') as string
  const snapshot = await firebaseAdmin
    .firestore()
    .collection('ad-campaigns')
    .where('page', '==', `/${adRedirect}`)
    .limit(1)
    .get()

  if (snapshot.empty) {
    return {
      props: {
        campaign: null,
      },
    }
  }

  const campaign = snapshot.docs[0].data() as AdCampaign | null

  return {
    props: {
      campaign: campaign ?? null,
    },
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  const snapshot = await firebaseAdmin
    .firestore()
    .collection('ad-campaigns')
    .get()
  return {
    fallback: true,
    paths: snapshot.docs.map((doc) => {
      return (doc.data().page as string) || ''
    }),
  }
}
